<template>
  <ui-component-modal
    modalTitle="Create new option"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveOption"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table
        v-if="newOption"
        class="table is-fullwidth is-striped"
      >
        <tbody>
          <tr>
            <td>Name</td>
            <td>
              <input
                type="text"
                v-model="newOption.Name"
                class="input"
              />
            </td>
          </tr>
          <tr>
            <td>Category</td>
            <td>
              <div class="control">
                <label
                  v-for="(category, index) in categories"
                  :key="index"
                  class="radio"
                >
                  <input
                    type="radio"
                    v-model="newOption.CategoryId"
                    :checked="newOption.CategoryId === category.Type"
                    :value="category.Type"
                    name="category"
                  />
                  {{ category.Name }}
                </label>
              </div>
            </td>
          </tr>
          <tr v-if="exportgroups.length > 0">
            <td>Exportgroup</td>
            <td>
              <div class="select">
                <select v-model="newOption.ExportgroupId">
                  <option :value="0">None</option>
                  <option
                    v-for="exportgroup in exportgroups"
                    :key="exportgroup.Id"
                    :value="exportgroup.Id"
                    :selected="newOption.ExportgroupId === exportgroup.Id"
                  >{{ exportgroup.Name }}</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Channels</td>
            <td>
              <div
                v-for="(channel, index) in channels"
                :key="index"
              >
                <label>
                  <input
                    type="checkbox"
                    :value="channel.ChannelId"
                    v-model="newOption.Channels"
                  />
                  {{ channel.ChannelName }}
                </label>
              </div>
            </td>
          </tr>

          <tr v-if="meetingtypes.length > 0">
            <td>Meetingtypes</td>
            <td>
              <div
                v-for="(meetingtype, index) in meetingtypes"
                :key="index"
              >
                <label>
                  <input
                    type="checkbox"
                    :value="meetingtype.MeetingtypeId"
                    v-model="newOption.Meetingtypes"
                  />
                  {{ meetingtype.MeetingtypeId | getMeetingtypeName }}
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import locationProvider from '@/providers/location'
import optionProvider from '@/providers/option'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      categories: [],
      channelId: Number(this.$route.params.channelId),
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      exportgroups: [],
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newOption: null,
    }
  },
  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),

    channels() {
      let channels = []

      if (this.locationState) {
        channels = this.locationState.Channels.filter(
          (c) => c.ChannelId === this.channel.Id
        )
      } else {
        if (this.channel) {
          channels.push({
            ChannelId: this.channel.Id,
            ChannelName: this.channel.Name,
          })
        }
      }

      return channels
    },

    meetingtypes() {
      if (this.locationState) {
        return this.locationState.Meetingtypes
      } else {
        let meetingtypes = []

        if (this.channel) {
          for (let i = 0; i < this.channel.Meetingtypes.length; i++) {
            let mt = this.channel.Meetingtypes[i]

            meetingtypes.push({
              MeetingtypeId: mt.MeetingtypeId,
            })
          }
        }
        return meetingtypes
      }
    },
  },

  created() {
    if (this.locationId > 0) {
      this.getLocationExportgroups()
    }

    this.categories.push({ Id: 1, Type: 'FB', Name: 'Food & beverage' })
    this.categories.push({ Id: 2, Type: 'AV', Name: 'Audio visual' })
    this.categories.push({ Id: 3, Type: 'Parking', Name: 'Parking' })
    this.categories.push({ Id: 4, Type: 'Other', Name: 'Other' })

    this.newOption = this.setupNewOptionData()
  },

  methods: {
    ...mapMutations('optionStore', ['setOption']),

    getLocationExportgroups() {
      locationProvider.methods
        .getLocationExportgroups(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            this.exportgroups = response.data
          }
        })
    },

    getExportgroupName(exportgroupId) {
      let name = ''
      let exportgroupIndex = this.exportgroups.findIndex(
        (e) => e.Id === exportgroupId
      )

      if (exportgroupIndex > -1) {
        name = this.exportgroups[exportgroupIndex].Name
      }
      return name
    },

    saveOption() {
      if (this.locationId > 0) {
        this.saveLocationOption()
      } else {
        this.saveChannelOption()
      }
    },

    saveChannelOption() {
      let self = this
      self.isSaving = true

      optionProvider.methods
        .createChannelOption(self.newOption)
        .then((response) => {
          if (response.status === 200) {
            self.setOption(response.data)
            self.isSavingSuccess = true

            let t = setTimeout(() => {
              self.goToOption(response.data.Id)
              self.onClickCancel()
              clearTimeout(t)
            }, 1500)
          }
        })
        .catch((e) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    saveLocationOption() {
      let self = this
      self.isSaving = true

      optionProvider.methods
        .createLocationOption(self.newOption)
        .then((response) => {
          if (response.status === 200) {
            self.setOption(response.data)
            self.isSavingSuccess = true

            let t = setTimeout(() => {
              self.goToOption(response.data.Id)
              self.onClickCancel()
              clearTimeout(t)
            }, 1500)
          }
        })
        .catch((e) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },

    goToOption(optionId) {
      if (this.locationId > 0) {
        this.$router.push({
          name: `locations-options-detail`,
          params: { optionId: optionId },
        })
      } else {
        this.$router.push({
          name: `options-detail`,
          params: { optionId: optionId },
        })
      }
    },

    setupNewOptionData() {
      let option = {
        LocationId: this.locationId,
        ChannelId: this.channel.Id,
        Name: '',
        Description: '',
        CategoryId: 'FB',
        ExportgroupId: 0,
        Channels: [],
        Meetingtypes: [],
        TaxId: 0,
        PriceType: 0,
        Price: 0,
      }

      if (this.locationId === 0) {
        option.Channels.push(this.channel.Id)
      }

      return option
    },
  },
}
</script>
